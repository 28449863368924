/* global defer */
import React from 'react'
import ReactDOM from 'react-dom'
import AutoComplete from '../shared/components/AutoComplete'

defer(() => {
  $('form[name=site-search]').each((ix, el) => {
    let mount = $(el)
    let site = mount.data('site')
    let siteArea = mount.data('site-area')

    const ajax = window.jQuery.ajax
    const dataSource = (value, callback) => {
      ajax({url: `/search-rac?site=${site}&site-area=${siteArea}&q=${value}`})
        .done(results => callback(results.map(function (item) {
          return {name: item.name, url: item.url}
        })))
    }

    const submitHandler = () => {
      mount.submit()
    }

    // const textInput = useRef(null);
    // const clearSearch = () => {
    //   // something here
    //   console.log('REF')
    // }

    if (el) {
      ReactDOM.render(
        <AutoComplete
          placeholder="Search"
          inputName="query"
          blockClass="a-text-field a-text-field--search"
          inputClass="js-raised-search-input"
          onSubmit={submitHandler}
          dataSource={dataSource}
          showClearButton="true"
        >
          <button type="submit" className="a-text-field__icon-search">
            <i className="a-icon ui-ic-search" title="Perform search"></i>
          </button>
        </AutoComplete>,
        el
      )
    }
  })
})
